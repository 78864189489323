import { StoreContext } from "../store";
import { Box, Container, Flex, HStack, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { useContext } from "react";
import Chatbox from "./ChatBox";
import Message from "./Message";
import CardMessage from "./CardMessage";
import { AnimatePresence } from "framer-motion";

export default function AppContainer() {
	const { state: { status: { page }, status }, choices, setStatus, clearChoices } = useContext(StoreContext);
	const color = useColorModeValue('gray.300', 'gray.700');

	const selectChoice = (body: string) => {
		const message_list = page.content.right_upper_panel.message_list;
		message_list.push({ role: 'assistant', content: body });
		setStatus(status);
		clearChoices();
	};

	return (
		<>
			<AnimatePresence>
				{
					page.content.right_upper_panel.message_list.length === 0 ? (
						<VStack
							h="100%"
							maxW="100vw"
							maxH="100vh"
							spacing={24}
							paddingTop={24}
							paddingBottom={12}
						>
							{
								!page.content.right_lower_panel.hidden && (
									<Chatbox />
								)
							}
						</VStack>

					) : (
						<VStack
							maxW="100vw"
							maxH="100vh"
							spacing={6}
							paddingTop={24}
							paddingBottom={12}
						>
							{
								[
									<Container
										className="messages__box"
										maxW={'container.xl'}
									>
										<Flex
											// divider={<StackDivider borderColor={color} />}
											direction="column"
											gap={6}
										>
											<AnimatePresence>
												{
													page.content.right_upper_panel.message_list.map((el, idx) => (
														el.card_format ? (
															<CardMessage key={idx} id={idx} message={el} />
														) : (
															<Message key={idx} id={idx} message={el} />
														)
													))
												}
											</AnimatePresence>

											{
												Object.keys(Object.fromEntries(choices)).length > 0 && (
													<HStack
														alignItems='stretch'
													>
														{
															Object.values(Object.fromEntries(choices)).map((el, idx) => (
																<Box
																	w="50%"
																	rounded="lg"
																	flex="1"
																	p={4}
																	borderColor={color}
																	cursor="pointer"
																	_hover={{ backgroundColor: color }}
																	borderWidth="1px"
																	onClick={() => selectChoice(el)}
																>
																	<Text fontSize="large" fontWeight="bolder" mb={1.5}>Response {idx + 1}</Text>
																	<Text>{el}</Text>
																</Box>
															))
														}
													</HStack>
												)
											}
										</Flex>
									</Container>,
									<>
										{
											!page.content.right_lower_panel.hidden && (
												<Chatbox />
											)
										}
									</>
								]
							}
						</VStack>
					)
				}
			</AnimatePresence>
		</>
	);
}
