import { StoreContext } from "../store";
import { Container, VStack, Text, Button, Select, HStack, Image, Menu, MenuButton, MenuItem, MenuList, Flex, Input, Card, Box, InputGroup, InputRightElement, Tooltip } from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslate } from "@tolgee/react";
import FileCard from "./FileCard";
import { AnimatePresence, motion } from "framer-motion";

export default function Chatbox() {
	const { t } = useTranslate();
	const inputFile = useRef<HTMLInputElement>(null);
	const { state: { status, currentChat, files }, state, callAction, actionLoading, talkToChat, addFile, setState } = useContext(StoreContext);
	const { page } = status;
	const [text, setText] = useState('');

	useEffect(() => {
		setText(page.content.right_lower_panel.prompt_bar.value || '');
	}, [page.content.right_lower_panel.prompt_bar.value]);

	const submitText = async () => {
		if (!page.content.right_lower_panel.send_button.action) return;
		page.content.right_lower_panel.prompt_bar.inner_text = text;
		if (currentChat && page.type === 'chat') {
			await talkToChat(page.content.right_lower_panel.prompt_bar.inner_text, currentChat.id);
		} else {
			console.log(!!page.content.right_lower_panel.send_button.streaming);
			await callAction(page.content.right_lower_panel.send_button.action, !!page.content.right_lower_panel.send_button.streaming, status);
		}
		setText('');
	};

	const clickCard = async (text: string) => {
		setText(text);
		setTimeout(() => {
			document.getElementById('chat__button')?.click();
		}, 100);
	};

	return (
		<Container
			maxW={'container.xl'}
			marginX={'auto'}
		>
			<VStack align="start" spacing={6}>
				{
					!page.content.right_lower_panel.gpt_version_select.hidden && (
						<HStack minW='container.xs'>
							<Text fontWeight="semibold" width="160px">
								GPT version:
							</Text>
							<Select onChange={e => page.content.right_lower_panel.gpt_version_select.selected = e.target.value}>
								{
									page.content.right_lower_panel.gpt_version_select.options!.map((option, i) => (
										<option key={i} value={option}>{option}</option>
									))
								}
							</Select>
						</HStack>
					)
				}

				{
					files.length > 0 && (
						<HStack w="100%" overflowX="auto">
							{
								files.map((file, i) => (
									<FileCard idx={i} file={file} onClose={() => {
										// Remove file with index i
										files.splice(i, 1);
										setState(state);
									}} />
								))
							}
						</HStack>
					)
				}

				{
					page.content.right_upper_panel.message_list.length !== 0 && page.content.right_lower_panel.suggestions && (
						<HStack w="100%" spacing={6}>
							{
								page.content.right_lower_panel.suggestions.map(el => (
									<Card
										w="100%"
										h="100%"
										minH="72px"
										borderRadius={16}
										position="relative"
										onClick={() => clickCard(el.prompt)}
									>
										<Card
											w="100%"
											h="72px"
											bg="#18274488"
											maxH="72px"
											borderRadius={16}
											position="absolute"
											top={0}
											left={0}
											p={2}
											fontSize="larger"
											userSelect="none"
											cursor="pointer"
											_hover={{
												bg: '#182744aa'
											}}
											color="white"
										>
											{el.prompt}
										</Card>

										<Box
											h="4px"
											w="96px"
											bg="#EB5947"
											position="absolute"
											bottom={0}
											left="calc(50% - 48px)"
										/>
									</Card>
								))
							}
						</HStack>
					)
				}

				{
					!page.content.right_lower_panel.prompt_bar.hidden && (
						<Container maxW={'100%'} padding={0} position={'relative'} bg="#FCECE4" p={6} borderRadius={16}>
							<form
								onSubmit={e => {
									e.preventDefault();
									submitText();
								}}
							>
								<InputGroup size="lg">
									<Input
										bg="white"
										shadow="lg"
										rounded={16}
										color="#132036"
										placeholder={page.content.right_lower_panel.prompt_bar.placeholder}
										_placeholder={{ color: '#444444' }}
										focusBorderColor="#EB5947"
										_hover={{
											borderColor: '#EB5947'
										}}
										isDisabled={actionLoading === page.content.right_lower_panel.send_button.action}
										borderColor="white"
										borderWidth={2}
										value={text}
										onChange={(e: any) => setText(e.target.value)}
									/>

									<InputRightElement>
										<Tooltip background="#858B9B" label='Houd er rekening mee dat de volgende inhoud is gegenereerd door een AI-systeem en fouten, inconsistenties of verouderde informatie kan bevatten.'>
											<Button
												id="chat__button"
												type="submit"
												color="#EB5947"
												colorScheme="white"
												isLoading={actionLoading === page.content.right_lower_panel.send_button.action}
												isDisabled={text.length === 0 || !!actionLoading}
											>
												<FontAwesomeIcon icon="paper-plane" color="#EB5947" />
											</Button>
										</Tooltip>
									</InputRightElement>
								</InputGroup>
							</form>

							{/* File upload */}
							<input
								type='file'
								id='file'
								accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, text/csv, text/plain"
								ref={inputFile}
								style={{ display: 'none' }}
								onChange={e => e.target.files && addFile(e.target.files[0])}
							/>

							{
								page.variables && page.variables["rag"] && (
									<Menu>
										{() => (
											<>
												<MenuButton
													as={Button}
													size="md"
													minW='50px'
													rounded={16}
													style={{ position: 'absolute', right: 68, bottom: 10, zIndex: 99 }}
													isDisabled={!!actionLoading}
													variant="outline"
													color="gray.300"
												>
													<FontAwesomeIcon icon="upload" />
												</MenuButton>
												<MenuList p={0}>
													<MenuItem
														fontWeight="medium"
														px={4}
														py={2}
														onClick={(e) => {
															if (inputFile.current) inputFile.current.click();
														}}
													>
														<HStack w="100%">
															<FontAwesomeIcon width={20} height={20} icon="file" />
															<Text>Upload files</Text>
														</HStack>
													</MenuItem>
													<MenuItem
														fontWeight="medium"
														px={4}
														py={2}
														isDisabled={true}
													>
														<HStack w="100%">
															<Image width={5} height={5} src="/favicon.ico" />
															<Text>Import from AI Factory</Text>
														</HStack>
													</MenuItem>
												</MenuList>
											</>
										)}
									</Menu>
								)
							}
						</Container>
					)
				}

				<AnimatePresence>
					{
						page.content.right_upper_panel.message_list.length === 0 && page.content.right_lower_panel.suggestions && (
							<HStack
								as={motion.div}
								w="100%"
								spacing={6}
							>
								{
									page.content.right_lower_panel.suggestions.map(el => (
										<Card
											w="100%"
											h="128px"
											borderRadius={16}
											overflow="hidden"
											position="relative"
											onClick={() => clickCard(el.prompt)}
										>
											<Image
												objectFit='cover'
												maxH="128px"
												src={el.image}
												fallbackSrc="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAACACAQAAADXXhmAAAABPklEQVR42u3UQQ0AAAjEME450sEGCa2EPZbpAp6KAYABAAYAGABgAIABAAYAGABgAIABAAYAGABgAIABAAYAGABgAIABAAYAGABgAIABAAYAGABgAIABAAYAGABgAIABAAYAGABgAIABAAYAGABgAGAABgAGABgAYACAAQAGABgAYACAAQAGABgAYACAAQAGABgAYACAAQAGABgAYACAAQAGABgAYACAAQAGABgAYACAAQAGABgAYACAAQAGABgAYACAAYABiAAGABgAYACAAQAGABgAYACAAQAGABgAYACAAQAGABgAYACAAQAGABgAYACAAQAGABgAYACAAQAGABgAYACAAQAGABgAYACAAQAGABgAYACAAYABAAYAGABgAIABAAYAGABgAIABAAYAGABgAIABAAYAGABgAMAtC5Q1v4En2jY2AAAAAElFTkSuQmCC"
												alt={el.prompt}
											/>

											<Card
												w="100%"
												h="100%"
												bg="#18274466"
												maxH="128px"
												borderRadius={16}
												position="absolute"
												top={0}
												left={0}
												p={4}
												fontWeight="bolder"
												fontSize="large"
												userSelect="none"
												cursor="pointer"
												_hover={{
													bg: '#18274499'
												}}
												color="white"
											>
												{el.prompt}
											</Card>

											<Box
												h="4px"
												w="96px"
												bg="#EB5947"
												position="absolute"
												bottom={0}
												left="calc(50% - 48px)"
											/>
										</Card>
									))
								}
							</HStack>
						)
					}
				</AnimatePresence>

				<Text alignSelf="center" fontSize="sm" color="gray.500">
					Deze chatbot kan fouten maken. Controleer <Tooltip background="#858B9B" label='Houd er rekening mee dat de volgende inhoud is gegenereerd door een AI-systeem en fouten, inconsistenties of verouderde informatie kan bevatten.'><u>belangrijke info</u></Tooltip>
				</Text>

				{
					!page.content.right_lower_panel.hints.hidden && page.content.right_lower_panel.hints.inner_text && (
						<Text alignSelf="center" fontSize="sm" color="gray.500">
							<ReactMarkdown children={t(page.content.right_lower_panel.hints.inner_text)} />
						</Text>
					)
				}
			</VStack>
		</Container>
	);
}
