import { SearchResult, SelectableResult } from "@/types/dynamic-app";
import { StoreContext } from "../../store";
import { Card, CardBody, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { startCase, toLower } from "lodash";
import { useContext } from "react";

interface Props {
    callback: () => void
}

export default function PopoverSubContent({ callback }: Props) {
    const { state: { status }, setStatus } = useContext(StoreContext);
    const { page } = status;

    const select = (el: SearchResult) => {
        const results = page.content.left_panel.search_results;
        if (results.id_key) {
            results.selected_search = el;
        }
        results.results = [];

        const filters = page.content.left_panel.search_bar.filters;
        if (filters) {
            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                filters[keys[i]] = '';
            }
        }

        setStatus(status);
        callback();
    };

    return (
        <>
            {
                page.content.left_panel.search_results.results && page.content.left_panel.search_results.results.length > 0 && (
                    <VStack
                        userSelect="none"
                        maxH="256px"
                        overflowY="auto"
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '4px',
                            },
                            '&::-webkit-scrollbar-track': {
                                width: '6px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                background: '#e2e8f066',
                                borderRadius: '24px',
                            },
                        }}
                    >
                        {
                            page.content.left_panel.search_results.results.map(el => (
                                <Card w="100%" variant="outline" cursor="pointer" onClick={() => select(el)}>
                                    <CardBody padding={2}>
                                        <SimpleGrid columns={4} spacing={2}>
                                            {
                                                Object.keys(el).map(key => (
                                                    <VStack alignItems="start" spacing={0} key={key}>
                                                        <Text fontSize="xs" fontWeight="bold">{startCase(toLower(key))}</Text>
                                                        {
                                                            typeof el[key] === 'string' && (
                                                                <Text fontSize="xs">{el[key] as string}</Text>
                                                            )
                                                        }
                                                        {
                                                            Array.isArray(el[key]) && (
                                                                <Text fontSize="xs">
                                                                    {(el[key] as SelectableResult[]).map(el => el.id).join(', ')}
                                                                </Text>
                                                            )
                                                        }
                                                    </VStack>
                                                ))
                                            }
                                        </SimpleGrid>
                                    </CardBody>
                                </Card>
                            ))
                        }
                    </VStack>
                )
            }
        </>
    );
}