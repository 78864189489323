import React from 'react';
import ReactMarkdown from 'react-markdown';
import { motion } from 'framer-motion';

const TypingAnimation = ({ text }: { text: string }) => {
    return (
        <motion.div
            initial={{ width: 0 }}
            animate={{ width: "100%" }}
            transition={{ duration: 2, ease: "easeInOut" }}
            style={{ overflow: "hidden", whiteSpace: "nowrap" }}
        >
            <ReactMarkdown>{text}</ReactMarkdown>
        </motion.div>
    );
};

export default TypingAnimation;
