import { VStack, HStack, FormControl, FormLabel, Button } from "@chakra-ui/react";
import DynamicContent from "./DynamicContent";
import Search from "../search/Search";
import { StoreContext } from "../../store";
import { useContext } from "react";
import ClientInfo from "../search/ClientInfo";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

export default function SidebarContent() {
    const navigate = useNavigate();
    const { state: { status: { page } }, loading, resetState } = useContext(StoreContext);

    return (
        <VStack w="100%" h="100%" justifyContent="space-between" paddingBottom={4}>
            <VStack w="100%" h="100%">
                {
                    page.content.left_panel.search_results && !page.content.left_panel.search_results.hidden && (
                        <Search />
                    )
                }

                <DynamicContent form={page.content.left_panel} columns={1} />
            </VStack>

            {
                page.content.left_panel.search_results && page.content.left_panel.search_results.selected_search && page.content.left_panel.search_results.selected_search[page.content.left_panel.search_results.id_key!] && (
                    <ClientInfo />
                )
            }
        </VStack>
    );
}