import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../components/Header";
import { StoreContext } from "../store";
import { Card, Container, HStack, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import { useTranslate } from "@tolgee/react";

export default function Apps() {
    const { t } = useTranslate();
    const { state: { apps }, loading, authenticating, fetchApps, resetState } = useContext(StoreContext);
    const color = useColorModeValue('gray.700', 'gray.400');
    const bgColor = useColorModeValue('gray.100', 'gray.700');
    const navigate = useNavigate();

    useEffect(() => {
        if (!authenticating || !process.env.REACT_APP_AUTH) fetchApps();
    }, [authenticating]);

    if (loading || authenticating) return <Loading />;
    return (
        <Container
            minW="100%"
            minH="100vh"
            padding={0}
            margin={0}
        >
            <Container
                minW="100%"
                minH="100vh"
                padding={0}
                margin={0}
                flexDir='row'
                display='flex'
            >
                <Container
                    minW="100%"
                    minH="100vh"
                >
                    <Header />

                    <Container
                        maxW="container.md"
                        minH="100vh"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <VStack
                            w="100%"
                            spacing={2}
                        >
                            {/* <Text fontWeight="medium" w="100%">{ t('apps.title') }</Text> */}
                            <Text fontWeight="medium" w="100%">Apps</Text>
                            {
                                apps && apps.length > 0 && apps.map((el, idx) => (
                                    <Card
                                        key={idx}
                                        w="100%"
                                        variant="outline"
                                        padding={4}
                                        userSelect="none"
                                        cursor="pointer"
                                        onClick={() => {
                                            resetState();
                                            navigate(`/${el.name}`)
                                        }}
                                        _hover={{
                                            background: bgColor,
                                        }}
                                    >
                                        <HStack spacing={4}>
                                            <FontAwesomeIcon icon={el.emoticon || 'comments'} color="gray.500" size='2xl' width={36} />
                                            <VStack spacing={1}>
                                                <Text fontWeight="medium" fontSize="medium" w="100%">{t(el.title)}</Text>
                                                <Text fontSize="small" textColor={color} w="100%">{t(el.description)}</Text>
                                            </VStack>
                                        </HStack>
                                    </Card>
                                ))
                            }
                        </VStack>
                    </Container>
                </Container>
            </Container>
        </Container>
    );
}