import ResizeTextarea from 'react-textarea-autosize';
import { Textarea, forwardRef } from '@chakra-ui/react';

const AutoResizeTextarea = forwardRef((props, ref) => (
	<Textarea
		w="100%"
		resize="none"
		ref={ref}
		as={ResizeTextarea}
		{...props}
	/>
));

export default AutoResizeTextarea;
