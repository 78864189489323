import { Box, Button, Card, Grid, HStack, Image, Link, SimpleGrid, Skeleton, StackDivider, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { CardContent, Message } from "../types/dynamic-app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMarkdown from 'react-markdown';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import { useContext, useEffect, useRef, useState } from 'react';
import DynamicContent from './sidebar/DynamicContent';
import AutoResizeTextarea from './AutoResizeTextarea';
import { ReactNode } from 'react';
import { StoreContext } from '../store';
import Logs from './Logs';
import { Log } from "@/types/log";
import { AnimatePresence, motion } from "framer-motion";
import DotPulse from "./DotPulse";
import TextAnim from "./typing/TextAnim";

interface Props {
	id: number;
	message: Message;
}

export default function MessageComponent({ message: el, id: key }: Props) {
	const [isCollapsed, setIsCollapsed] = useState(el.collapsed);
	const color = '#444444';
	const assistantColor = '#FBECE4';
	const userColor = '#FBECE4';

	const truncateText = (text: string, maxLength: number) => {
		if (text.length <= maxLength) return text;
		return text.substr(0, maxLength) + '...';
	};

	// Not sure what the actual type is

	const newTheme = {
		a: (props: { href?: string; children: ReactNode }) => {
			const { href, children } = props;
			return (
				<Link href={href ?? '#'} color="blue.500" textDecoration="underline">
					{children}
				</Link>
			);
		},
	};

	const [cardContent, setCardContent] = useState<CardContent>();

	useEffect(() => {
		if (!el.content) return;
		try {
			const parsedContent: CardContent = JSON.parse(el.content);
			setCardContent(parsedContent);
		} catch { }
	}, [el.content]);

	return (
		<>
			<Card
				as={motion.div}
				borderTopRadius={16}
				borderBottomRightRadius={16}
				borderBottomLeftRadius={0}
				padding={4}
				color={color}
				shadow="none"
				position="relative"
				backgroundColor={el.color || (el.role === 'assistant' ? assistantColor : userColor)}
				mr={el.role === 'user' ? 6 : 0}
				ml={el.role === 'assistant' ? 6 : 0}
				initial={{ x: el.role === 'user' ? 100 : -100, opacity: 0 }}
				animate={{ x: 0, opacity: 1 }}
				exit={{ x: el.role === 'user' ? 100 : -100, opacity: 0 }}
				transition={{ "ease": "linear", duration: "0.5" }}
				width={cardContent ? '100%' : '128px'}
			>
				<Box
					position="absolute"
					bottom={0}
					right={el.role === 'user' ? -25 : undefined}
					left={el.role === 'assistant' ? -25 : undefined}
					width="0"
					height="0"
					borderTop="25px solid #ffffff00"
					borderLeft={el.role === 'user' ? '25px solid #FBECE4' : ''}
					borderRight={el.role === 'assistant' ? '25px solid #FBECE4' : ''}
				/>

				<VStack
					spacing={8}
					position="relative"
					w="100%"
					role='group'
				>
					{
						cardContent ? (
							<>
								{
									cardContent?.introduction && (
										<Text
											color={color}
											fontSize="larger"
											w="100%"
										>
											<ReactMarkdown components={ChakraUIRenderer(newTheme)} children={cardContent.introduction} skipHtml />
										</Text>
									)
								}

								<SimpleGrid w="100%" minChildWidth='256px' gap={6} paddingInline={8}>
									{
										cardContent?.items && cardContent.items.map(el => (
											<Card
												as="a"
												target="_blank"
												rel="noopener noreferrer"
												href={el.link}
												w="100%"
												borderRadius={24}
												position="relative"
												backgroundImage={el.image && el.image !== '' ? `url(${el.image})` : ''}
												backgroundSize="cover"
												minH="200px"
											>
												<Card
													w="100%"
													h="100%"
													minH="200px"
													bg="#182744aa"
													borderRadius={0}
													p={4}
													fontWeight="bolder"
													fontSize="large"
													userSelect="none"
													cursor={el.link && "pointer"}
													gap={2}
													overflow="hidden"
												>
													<AnimatePresence>
														<Text
															fontSize="x-large"
															fontWeight={600}
															textColor="white"
															textAlign="center"
															w="100%"
														>
															{el.title}
														</Text>
														<Text
															fontSize="19.2px"
															textColor="gray.300"
															noOfLines={6}
														>
															{el.description}
														</Text>
													</AnimatePresence>
												</Card>

												<Box
													h="4px"
													w="96px"
													bg="#EB5947"
													position="absolute"
													bottom={0}
													left="calc(50% - 48px)"
												/>
											</Card>
										))
									}
								</SimpleGrid>

								{
									cardContent?.conclusion && (
										<Text
											color={color}
											fontSize="larger"
											w="100%"
										>
											{/* <ReactMarkdown components={ChakraUIRenderer(newTheme)} children={cardContent.conclusion} skipHtml /> */}
											<TextAnim text={cardContent.conclusion} />
										</Text>
									)
								}
							</>
						) : (
							<DotPulse />
						)
					}
				</VStack>
			</Card>
		</>
	);
}
