import { extendTheme } from '@chakra-ui/react';
import '@fontsource/montserrat';
import { theme as baseTheme } from '@saas-ui/theme-glass';

const theme = extendTheme({
    initialColorMode: 'light',
    useSystemColorMode: false,
    fonts: {
        body: `'Montserrat', sans-serif`,
    },
    styles: {
        global: {
            body: {
                bg: 'white'
            }
        }
    },
    baseTheme,
});

export default theme;