import { StoreContext } from "../store";
import { ChevronLeftIcon, HamburgerIcon, MoonIcon, SunIcon } from "@chakra-ui/icons";
import { Button, Container, HStack, useColorMode } from "@chakra-ui/react";
import { useContext } from "react";
import ModelSwitcher from "./ModelSwitcher";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
	onOpen?: () => void;
}

export default function Header(props: Props) {
	const { colorMode, toggleColorMode } = useColorMode();
	const navigate = useNavigate();
	const location = useLocation();
	const { state: { status }, resetState } = useContext(StoreContext);
	const { page } = status;

	return (
		<Container maxW='100%' position='absolute' left={0} pointerEvents="none" top={0} paddingBlock={4} justifyContent='space-between' display='flex'>
			<HStack pointerEvents="all">
				{
					location.pathname !== '/' && (
						<>
							{
								page && page.content.left_panel['sidebar'] && page.content.left_panel['sidebar'].slider && (
									<Button variant='outline' onClick={() => {
										resetState();
										navigate('/');
									}}>
										<FontAwesomeIcon icon="home" />
									</Button>
								)
							}

							{
								page && page.content.left_panel['sidebar'] && page.content.left_panel['sidebar'].slider ? (
									<Button variant='outline' onClick={props.onOpen}>
										<HamburgerIcon />
									</Button>
								) : <div></div>
							}
						</>
					)
				}
			</HStack>

			{/* <HStack pointerEvents="all">
				{
					page && page.type === 'chat' && (
						<ModelSwitcher />
					)
				}

				<Button variant='outline' onClick={toggleColorMode}>
					{
						colorMode === 'dark' ? (
							<SunIcon />
						) : (
							<MoonIcon />
						)
					}
				</Button>
			</HStack> */}
		</Container>
	);
}
