import { StoreContext } from "../../store";
import { Button, Card, Flex, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, SimpleGrid, Stack, Text, Tooltip, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import AutoResizeTextarea from "../AutoResizeTextarea";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DynamicForm } from "@/types/dynamic-app";
import Dropzone from "react-dropzone";
import { CloseIcon } from "@chakra-ui/icons";
import { useTranslate } from "@tolgee/react";

interface Props {
	form: DynamicForm;
	columns: number;
}

export default function DynamicContent({ form, columns }: Props) {
	const SPACING = '8px';

	const { t } = useTranslate();
	const { state: { status }, setStatus, callAction, actionLoading } = useContext(StoreContext);

	const { register, control, setValue } = useForm();
	const [prevVals, setPrevVals] = useState(new Map<string, string>());
	const matches = useWatch({ control });
	const bg = useColorModeValue('gray.100', 'gray.700');
	const text = useColorModeValue('gray.500', 'gray.200');

	useEffect(() => {
		for (const key of Object.keys(matches)) {
			const value = matches[key];
			const element = form[key];
			if (element.on_match && element.value !== prevVals.get(key)) {
				const re = new RegExp(element.on_match);
				if (re.exec(value)) {
					(async () => {
						if (element.action) {
							const res = await callAction(element.action, false, status);
							if (res) {
								for (const key of Object.keys(res.page.content.left_panel)) {
									const value = res.page.content.left_panel[key].value;
									if (value) {
										setValue(key, value);
									}
								}
							}
						}
					})();
				}
			}
			if (element.value) setPrevVals(prevVals.set(key, element.value));
		}
	}, [matches]);

	const handleAction = async (action?: string, streaming?: boolean) => {
		if (action) {
			await callAction(action, !!streaming, status);
		}
	};

	const {
		isOpen: isEditOpen,
		onOpen: onEditOpen,
		onClose: onEditClose,
	} = useDisclosure();
	const [editKey, setEditKey] = useState('');
	const editElement = (key: string) => {
		onEditOpen();
		setEditKey(key);
	};

	const onSelectChange = async (el: string, newValue: string) => {
		const element = form[el];
		element.selected = newValue;
		if (element.on_select) {
			await handleAction(element.on_select);
		}
	};

	return (
		<SimpleGrid columns={columns} spacing={4} minW='100%' maxH={'80%'} overflowY={'auto'} overflowX={'hidden'}>
			{
				Object.keys(form).map((el, idx) => (
					el !== 'title_left_panel' &&
					el !== 'search_results' &&
					el !== 'search_bar' &&
					el !== 'sidebar_hint' &&
					el !== 'sidebar' &&
					!form[el].hidden && (
						<FormControl
							isRequired={form[el].required}
							key={idx}
							isDisabled={!!actionLoading}
						>
							<HStack justifyContent="space-between">
								{
									form[el].title && (
										<FormLabel>
											<Tooltip label={form[el].tooltip} hasArrow={true} placement='bottom-start' openDelay={150} arrowPadding={4}>
												{t(form[el].title!)}
											</Tooltip>
										</FormLabel>
									)
								}

								{
									form[el].type === 'input_textarea' && (
										<Button size="xs" onClick={() => editElement(el)}>
											Edit
										</Button>
									)
								}
							</HStack>
							{
								form[el].type === 'input_text' && (
									<Input
										placeholder={t(form[el].placeholder!)}
										defaultValue={form[el].value}
										{...register(el, {
											required: form[el].required && 'This is required',
											onChange: e => form[el].value = e.target.value
										})}
									/>
								)
							}
							{
								form[el].type === 'input_date' && (
									<Input
										type="date"
										placeholder={t(form[el].placeholder!)}
										defaultValue={form[el].value}
										{...register(el, {
											required: form[el].required && 'This is required',
											onChange: e => form[el].value = e.target.value
										})}
									/>
								)
							}
							{
								form[el].type === 'input_textarea' && (
									<AutoResizeTextarea placeholder={t(form[el].placeholder!)} defaultValue={form[el].value} maxRows={form[el].max_rows} {...register(el, {
										required: form[el].required && 'This is required',
										onChange: e => form[el].value = e.target.value
									})} />
								)
							}
							{
								form[el].type === 'select' && (
									<Select id={el} {...register(el, {
										required: form[el].required && 'This is required',
										onChange: e => onSelectChange(el, e.target.value),
									})}>
										<option selected hidden disabled value="">
											{t(form[el].placeholder!)}
										</option>
										{(form[el].options!).map((el, idx) => (
											<option value={el} key={el}>
												{el}
											</option>
										))}
									</Select>
								)
							}
							{
								form[el].type === 'button_group' && (
									<Flex
										flexWrap="wrap"
										justifyContent="center"
										marginX={`calc(${SPACING} / 2)`}
									>
										{
											Object.keys(form[el].children as {}).map((cel, idx) => (
												<Button
													key={idx}
													flexBasis={`calc(50% - ${SPACING})`}
													marginBottom={SPACING}
													whiteSpace="normal"
													_even={{
														marginX: SPACING
													}}
													isDisabled={!!actionLoading}
													onClick={() => handleAction(form[el].children![cel].action)}
													leftIcon={<FontAwesomeIcon icon={form[el].children![cel].emoticon as IconName} />}
												>
													{
														t(form[el].children![cel].inner_text!)
													}
												</Button>
											))
										}
									</Flex>
								)
							}
							{
								form[el].type === 'file_input' && (
									<>
										{
											form[el].selected ? (
												<Card p={4} variant="outline" cursor="pointer" bg={bg}>
													<HStack w="100%">
														<Text w="100%" textAlign="center" fontSize="small" textColor={text}>Selected <b>{form[el].selected}</b></Text>
														<CloseIcon h={3} onClick={() => {
															form[el].selected = undefined;
															form[el].selected_data = undefined;
															setStatus(status);
														}} />
													</HStack>
												</Card>
											) : (
												<Dropzone accept={form[el].accept} maxFiles={1} onDrop={files => {
													const file = files[0];
													
													form[el].selected = file.name;
													setStatus(status);

													const reader = new FileReader();
													reader.readAsDataURL(file);
													reader.onload = () => {
														if (reader.result) {
															form[el].selected_data = reader.result.toString();
														}
														setStatus(status);
														handleAction(form[el].on_upload, form[el].streaming);
													};
												}}>
													{({ getRootProps, getInputProps }) => (
														<div {...getRootProps()}>
															<input {...getInputProps()} />
															<Card borderStyle="dashed" p={4} variant="outline" cursor="pointer" bg={bg}>
																<Text textAlign="center" fontSize="small" textColor={text}>{t('input.file.text')}</Text>
															</Card>
														</div>
													)}
												</Dropzone>
											)
										}
									</>
								)
							}
						</FormControl>
					)
				))
			}

			<Modal isOpen={isEditOpen} onClose={onEditClose} size="xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						{editKey.length > 0 && form[editKey].title}
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<AutoResizeTextarea
							placeholder={editKey.length > 0 && form[editKey].placeholder}
							id={editKey}
							{...register(editKey, {
								required: 'This is required',
							})}
							maxRows={40}
							style={{ overflowY: 'scroll' }}
						/>
					</ModalBody>

					<ModalFooter>
						<Button
							onClick={onEditClose}
						>
							Save
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</SimpleGrid>
	);
}
