import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ChakraProvider } from '@chakra-ui/react';
import StoreProvider from './store';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Apps from './pages/Apps';
import App from './pages/App';
import Loading from './pages/Loading';
import { DevTools, FormatSimple, Tolgee, TolgeeProvider } from '@tolgee/react';
import theme from './theme';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
	{
		path: '/',
		element: <Apps />,
	},
	{
		path: '/loading',
		element: <Loading />,
	},
	{
		path: '/:appName',
		element: <App />,
	}
]);

const tolgee = Tolgee()
	.use(DevTools())
	.use(FormatSimple())
	.init({
		language: 'en',
		apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
		apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
	});

root.render(
	<React.StrictMode>
		<TolgeeProvider
			tolgee={tolgee}
			fallback="Loading..."
		>
			<ChakraProvider theme={theme}>
				<StoreProvider>
					<RouterProvider router={router} />
				</StoreProvider>
			</ChakraProvider>
		</TolgeeProvider>
	</React.StrictMode>
);
