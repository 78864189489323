import { StoreContext } from "../../store";
import { Box, Button, ButtonGroup, Card, CardBody, Container, Divider, FormControl, FormLabel, HStack, Input, InputGroup, InputLeftElement, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Radio, RadioGroup, SimpleGrid, Spinner, Stack, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, useContext, useRef, useState } from "react";
import { Element } from "@/types/dynamic-app";
import PopoverSubContent from './PopoverSubContent';
import DynamicContent from "../sidebar/DynamicContent";
import { useTranslate } from "@tolgee/react";

export default function Search() {
    const { t } = useTranslate();
    const inputRef = useRef<any>(); // HTMLInputElement, React being weird
    const { isOpen, onToggle, onClose } = useDisclosure();
    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();

    const { state: { status }, callAction, actionLoading } = useContext(StoreContext);
    const { page } = status;
    const [searchTerm, setSearchTerm] = useState('');

    const search = async () => {
        page.content.left_panel.search_bar.inner_text = searchTerm;
        await callAction('search', false, status);
        if (inputRef.current) inputRef.current.value = '';
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    return (
        <VStack w="100%" spacing={0} justifyItems="center" paddingBottom={8}>
            <FormControl
                isDisabled={!!actionLoading}
            >
                <HStack justifyContent="space-between" justifyItems="center">
                    <FormLabel>
                        {page.content.left_panel.search_results && page.content.left_panel.search_results.title && t(page.content.left_panel.search_results.title)}
                    </FormLabel>

                    <Button size="xs" isDisabled={!!actionLoading} onClick={() => {
                        if (inputRef.current) inputRef.current.value = '';
                        onClose();
                        onModalOpen();
                    }}>
                        {t('input.advanced')}
                    </Button>
                </HStack>
            </FormControl>

            <VStack
                width="100%"
                position="relative"
                alignItems="start"
                justifyItems="center"
                spacing={1}
            >
                <Popover
                    isOpen={isOpen}
                    onClose={onClose}
                    placement='right-start'
                    closeOnBlur={false}
                    matchWidth={true}
                    autoFocus={false}
                >
                    <PopoverTrigger>
                        <InputGroup>
                            <InputLeftElement pointerEvents='none'>
                                <FontAwesomeIcon icon={page.content.left_panel.search_bar.emoticon as IconName} color='#CBD5E0' />
                            </InputLeftElement>
                            {
                                actionLoading === 'search' && !isOpen && (
                                    <InputRightElement pointerEvents='none'>
                                        <Spinner size="sm" />
                                    </InputRightElement>
                                )
                            }
                            <Input
                                type='text'
                                placeholder={page.content.left_panel.search_bar.placeholder && t(page.content.left_panel.search_bar.placeholder)}
                                onChange={handleChange}
                                onKeyUp={e => {
                                    if (e.key === 'Enter') {
                                        if (searchTerm.length > 0) {
                                            search().then(() => {
                                                if (!isOpen) onToggle();
                                            });
                                        }
                                    }
                                }}
                                isDisabled={!!actionLoading}
                                ref={inputRef}
                            />
                            {
                                page.content.left_panel.search_bar.button && (
                                    <InputRightElement width='5rem'>
                                        <Button h='1.75rem' size='sm' onClick={search} isLoading={actionLoading === 'search'}>
                                            Search
                                        </Button>
                                    </InputRightElement>
                                )
                            }
                        </InputGroup>
                    </PopoverTrigger>
                    <PopoverContent width="100%">
                        <PopoverHeader fontWeight='semibold'>{(page.content.left_panel.search_results as Element).title}</PopoverHeader>
                        <PopoverCloseButton onClick={onClose} />
                        <PopoverBody>
                            <PopoverSubContent callback={onClose} />
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
                <Modal isOpen={isModalOpen} onClose={() => {
                    onModalClose();
                    page.content.left_panel.search_results.results = [];
                }} size="2xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader fontSize="lg">{t('input.advanced.title')}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody paddingBottom={4}>
                            <VStack spacing={4}>
                                <VStack alignItems="end" w="100%">
                                    {/* <SimpleGrid columns={2} spacing={4} w="100%">
                                        {
                                            page.content.left_panel.search_bar.filters && Object.keys(page.content.left_panel.search_bar.filters).map((el, idx) => (
                                                <VStack alignItems="start" w="100%" key={idx}>
                                                    <Text fontWeight="semibold">{el}</Text>
                                                    <Input
                                                        type={el.toLowerCase().includes('date') ? 'date' : 'text'}
                                                        placeholder={el}
                                                        onChange={e => page.content.left_panel.search_bar.filters![el] = e.target.value}
                                                    />
                                                </VStack>
                                            ))
                                        }
                                    </SimpleGrid> */}
                                    {
                                        page.content.left_panel.search_bar.advanced_search && (
                                            <DynamicContent columns={2} form={page.content.left_panel.search_bar.advanced_search} />
                                        )
                                    }
                                    <Button onClick={search} isLoading={actionLoading === 'search'}>
                                        Search
                                    </Button>
                                </VStack>
                                {
                                    page.content.left_panel.search_results.results && page.content.left_panel.search_results.results.length > 0 && (
                                        <>
                                            <Divider />
                                            <PopoverSubContent callback={onModalClose} />
                                        </>
                                    )
                                }
                            </VStack>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </VStack>
        </VStack>

    );
}