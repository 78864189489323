import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, HStack, Text, Tooltip, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { useContext } from "react";
import { StoreContext } from "../../store";
import ReactMarkdown from 'react-markdown';
import SidebarContent from "./SidebarContent";
import ChatSidebarContent from './ChatSidebarContent';
import { useTranslate } from "@tolgee/react";

library.add(fas);

interface Props {
	isOpen: boolean;
	onClose: () => void;
}

export default function Sidebar(props: Props) {
	const { t } = useTranslate();
	const { state: { status }, callAction, actionLoading } = useContext(StoreContext);
	const { page } = status;

	const handleAction = async (action?: string) => {
		if (action) {
			await callAction(action, false, status);
		}
	};

	return (
		<Drawer
			placement='left'
			size='md'
			isOpen={props.isOpen}
			onClose={props.onClose}
		>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader>
					<VStack>
						<HStack justifyContent='center' mt='8'>
							{
								page.content.left_panel.title_left_panel.emoticon && (
									<FontAwesomeIcon icon={page.content.left_panel.title_left_panel.emoticon as IconName} />
								)
							}

							<Text>
								{page.content.left_panel.title_left_panel.text && t(page.content.left_panel.title_left_panel.text)}
							</Text>
						</HStack>

						{
							page.content.left_panel.sidebar_hint && page.content.left_panel.sidebar_hint.inner_text && (
								<Text alignSelf="center" fontSize="sm" color="gray.500">
									<ReactMarkdown children={page.content.left_panel.sidebar_hint.inner_text} />
								</Text>
							)
						}
					</VStack>
				</DrawerHeader>

				<DrawerBody>
					{
						page.type === 'chat' ? (
							<ChatSidebarContent />
						) : (
							<SidebarContent />
						)
					}
				</DrawerBody>

				<DrawerFooter>
					<HStack spacing={4}>
						{
							Object.keys(page.content.left_panel).map((el, idx) => (
								!page.content.left_panel[el].hidden && page.content.left_panel[el].type === 'button' && (
									<Button
										variant={page.content.left_panel[el].variant}
										leftIcon={page.content.left_panel[el].emoticon ? <FontAwesomeIcon icon={page.content.left_panel[el].emoticon as IconName} /> : undefined}
										iconSpacing={2}
										key={idx}
										isLoading={!!actionLoading}
										onClick={() => handleAction(page.content.left_panel[el].action)}
									>
										<Text>{page.content.left_panel[el].text}</Text>
									</Button>
								)
							))
						}
					</HStack>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
